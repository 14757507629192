import { createContext, useState } from "react";

export const BlogContext = createContext(null);

export const BlogProvider = (props) => {
  const [post, setPost] = useState({});
  const [content, setContent] = useState("");
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  return (
    <BlogContext.Provider
      value={{
        post,
        setPost,
        content,
        setContent,
        categories,
        setCategories,
        tags,
        setTags,
      }}
    >
      {props.children}
    </BlogContext.Provider>
  );
};
