import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/reset.css";
import "./index.css";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import { FirebaseProvider } from "./context/firebase";
import { AdminProvider } from "./context/AdminContext";
import { ToastContainer } from "react-toastify";
import { BlogProvider } from "./context/BlogContext";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <FirebaseProvider>
          <BlogProvider>
            <AdminProvider>
              <App />
              <ToastContainer autoClose={2000} />
            </AdminProvider>
          </BlogProvider>
        </FirebaseProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
