import { createContext, useContext } from "react";
import { auth, database } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { child, get, ref, set } from "firebase/database";

const FirebaseContext = createContext(null);

export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider = (props) => {
  const signUp = async (email, password) => {
    await createUserWithEmailAndPassword(auth, email, password);
  };
  const signIn = (email, password) => {
    signInWithEmailAndPassword(auth, email, password);
  };
  const putData = (key, uid, data) => {
    set(ref(database, `${key}/${uid}`), data);
  };

  const getData = async (key, uid) => {
    try {
      const snapshot = await get(child(ref(database), key));
      if (snapshot.exists()) {
        const res = snapshot.val();
        return res[uid]; // Return data specific to the UID
      } else {
        console.log("No data available");
        return null;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  return (
    <FirebaseContext.Provider value={{ signUp, putData, getData, signIn }}>
      {props.children}
    </FirebaseContext.Provider>
  );
};
