import React, { useState } from "react";
import { Layout, Menu, Drawer, Button } from "antd";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Strip from "../../components/header/Strip";
import AppFooter from "../../components/footer/Footer";
import { IoIosMenu } from "react-icons/io";
import { items } from "../menus";
import "./index.css";
import { Helmet } from "react-helmet";

const { Header, Content } = Layout;
const { SubMenu } = Menu;

const renderMenuItem = (item, onClose) => {
  if (item.children) {
    return (
      <SubMenu key={item.key} 
      title={
        <div className="flex items-center">
          <Link to={item.path} className="formatted-text" onClick={onClose}>
            {item.label}
          </Link>
        </div>
      }
      
      className="formatted-text">
        {item.children.map((child) => renderMenuItem(child, onClose))}
      </SubMenu>
    );
  }
  return (
    <Menu.Item key={item.key} onClick={onClose}>
      {item?.isExternal ? (
        <a
          href={item.path}
          target="_blank"
          rel="noopener noreferrer"
          className="formatted-text border-0"
        >
          {item.label}
        </a>
      ) : (
        <Link to={item.path} className="formatted-text">
          {item.label}
        </Link>
      )}
    </Menu.Item>
  );
};

function LayoutTopBottom({ children }) {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://www.mlsclasses.com/" />
      </Helmet>
      <div className="fixed w-full z-20 bg-white">
        <Strip />
        <Header className="flex justify-between items-center bg-white p-4">
          <link
            rel="preload"
            href="/static/logo.webp"
            as="image"
            type="image/png"
          />
          <a href="/" className="flex items-center">
            <img src="/static/logo.webp" alt="logo" className="logo"/>
          </a>
          <Button className="hidden sm:block" type="text" onClick={showDrawer}>
            <IoIosMenu size={24} />
          </Button>
          <Menu
            className="custom-menu sm:hidden lg:flex flex-1 justify-end"
            theme="light"
            mode="horizontal"
          >
            {items.map((item) => renderMenuItem(item, onClose))}
          </Menu>
        </Header>
        <Drawer
          title="Menu"
          placement="right"
          onClose={onClose}
          visible={visible}
          bodyStyle={{ padding: 0 }}
        >
          <Menu mode="inline" className="custom-menu">
            {items.map((item) => renderMenuItem(item, onClose))}
          </Menu>
        </Drawer>
      </div>

      <Content className="min-h-[280px] flex-1">{children}</Content>
      <footer className="w-full text-white">
        <AppFooter />
      </footer>
    </div>
  );
}

export default LayoutTopBottom;
