import { createContext, useEffect, useState } from "react";
import { auth, firestoreDb } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
export const AdminContext = createContext(null);

export const AdminProvider = (props) => {
  const [admin, setAdmin] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state
  // Check auth state on mount
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const savedRole = localStorage.getItem("role");
        const docRef = doc(firestoreDb, `${savedRole}s`, user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = {
            id: docSnap.id,
            ...docSnap.data(),
          };
          setAdmin(userData);
        } else {
          //   toast.error("you are Logged Out");
          // console.log("inside else of unsubscribe") ;
          setAdmin(null);
        }
        setLoading(false);
      } else {
        setAdmin(null);
        setLoading(false);
      }
    });

    return () => unsubscribe(); // Clean up listener on unmount
  }, [setAdmin, navigate]);
  return (
    <AdminContext.Provider value={{ admin, setAdmin, loading }}>
      {props.children}
    </AdminContext.Provider>
  );
};
