// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBdGxf4DofhYmm2cM0K2QBe5KtXF0kuZrA",
  authDomain: "mls-classes-2024-new-b8650.firebaseapp.com",
  projectId: "mls-classes-2024-new-b8650",
  storageBucket: "mls-classes-2024-new-b8650.firebasestorage.app",
  messagingSenderId: "232567617770",
  appId: "1:232567617770:web:1b46b4ad9c9f32d9d5ff93",
  measurementId: "G-SMS2TMFN7G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const provider = new GoogleAuthProvider();
const database = getDatabase(app);
const firestoreDb = getFirestore(app);
const storage = getStorage(app);

export { app, auth, provider, database, firestoreDb, storage };
