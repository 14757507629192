import { IoIosArrowDown } from "react-icons/io";

export const items = [
  {
    key: "1",
    label: (
      <div className="flex items-center gap-2">
        Test Preparation <IoIosArrowDown className="sm:hidden" />
      </div>
    ),
    children: [
      {
        key: "1-1",
        label: "Digital SAT",
        path: "/test-prep/digital-sat",
        children: [
          {
            key: "1-1-2",
            label: "Digital SAT Math",
            path: "/test-prep/digital-sat/sat-math",
          },
          {
            key: "1-1-3",
            label: "Digital SAT Reading & Writing",
            path: "/test-prep/digital-sat/sat-read-write",
          },
        ],
      },
      {
        key: "1-2",
        label: "ACT",
        path: "/test-prep/act",
        children: [
          { key: "1-2-1", label: "ACT Math", path: "/test-prep/act/act-math" },
          {
            key: "1-2-2",
            label: "ACT English",
            path: "/test-prep/act/act-english",
          },
          {
            key: "1-2-3",
            label: "ACT Science",
            path: "/test-prep/act/act-science",
          },
          {
            key: "1-2-4",
            label: "ACT Reading & Writing",
            path: "/test-prep/act/act-read-write",
          },
        ],
      },
      {
        key: "1-3",
        label: "AP",
        path: "/test-prep/ap-test",
        children: [
          {
            key: "1-3-1",
            label: "AP Precalculus",
            path: "/ap-courses/ap-precalculus",
          },
          {
            key: "1-3-2",
            label: "AP Calculus AB",
            path: "/ap-courses/ap-calculus-ab",
          },
          {
            key: "1-3-3",
            label: "AP Calculus BC",
            path: "/ap-courses/ap-calculus-bc",
          },
          {
            key: "1-3-4",
            label: "AP Statistics",
            path: "/ap-courses/ap-statistics",
          },
          {
            key: "1-3-5",
            label: "AP Physics 1 & 2",
            path: "/ap-courses/ap-physics-1-2",
          },
          {
            key: "1-3-6",
            label: "AP Physics C: Electricity and Magnetism",
            path: "/ap-courses/ap-physics-c-electricity-magnetism",
          },
          {
            key: "1-3-7",
            label: "AP Physics C: Mechanics",
            path: "/ap-courses/ap-physics-c-mechanics",
          },
          {
            key: "1-3-8",
            label: "AP Chemistry",
            path: "/ap-courses/ap-chemistry",
          },
          { key: "1-3-9", label: "AP Biology", path: "/ap-courses/ap-biology" },
          {
            key: "1-3-10",
            label: "AP Environmental Science",
            path: "/ap-courses/ap-environmental-science",
          },
          {
            key: "1-3-11",
            label: "AP Computer Science A",
            path: "/ap-courses/ap-computer-science-a",
          },
          {
            key: "1-3-12",
            label: "AP English Literature and Composition",
            path: "/ap-courses/ap-english-literature-composition",
          },
          {
            key: "1-3-13",
            label: "AP Microeconomics",
            path: "/ap-courses/ap-microeconomics",
          },
          {
            key: "1-3-14",
            label: "AP Macroeconomics",
            path: "/ap-courses/ap-macroeconomics",
          },
        ],
      },
      {
        key: "1-4",
        label: "PSAT",
        children: [
          { key: "1-4-1", label: "PSAT 8/9", path: "/test-prep/psat/psat-8-9" },
          { key: "1-4-2", label: "PSAT 10", path: "/test-prep/psat/psat-10" },
          {
            key: "1-4-3",
            label: "PSAT/NMSQT",
            path: "/test-prep/psat/psat-nmsqt",
          },
        ],
      },
      {
        key: "1-5",
        label: "Other Test Prep",
        children: [
          { key: "1-5-1", label: "AMC 8", path: "/test-prep/amc-8" },
          { key: "1-5-2", label: "AMC 10,12", path: "/test-prep/amc-10" },
          { key: "1-5-3", label: "SSAT", path: "/test-prep/ssat" },
          { key: "1-5-4", label: "MATHCOUNTS", path: "/test-prep/mathcounts" },
          { key: "1-5-5", label: "MCAT", path: "/test-prep/mcat" },
          { key: "1-5-6", label: "CAASPP", path: "/test-prep/caaspp" },
          { key: "1-5-7", label: "STAAR", path: "/test-prep/staar" },
          { key: "1-5-8", label: "FSA", path: "/test-prep/fsa" },
          { key: "1-5-9", label: "Regents", path: "/test-prep/regents" },
          { key: "1-5-10", label: "HSPT", path: "/test-prep/hspt" },
          { key: "1-5-11", label: "PARCC", path: "/test-prep/parcc" },
          { key: "1-5-12", label: "TERRANOVA", path: "/test-prep/terranova" },
        ],
      },
    ],
  },
  {
    key: "2",
    label: (
      <div className="flex items-center gap-2">
        Academic Tutoring <IoIosArrowDown className="sm:hidden" />
      </div>
    ),
    children: [
      {
        key: "2-1",
        label: "US Curriculum",
        path: "/academic-tutoring/us-curriculum",
        children: [
          {
            key: "2-1-1",
            label: "Elementary School",
            path: "/academic-tutoring/us-curriculum/elementary-school",
          },
          {
            key: "2-1-2",
            label: "Middle School",
            path: "/academic-tutoring/us-curriculum/middle-school",
          },
          {
            key: "2-1-3",
            label: "High School",
            path: "/academic-tutoring/us-curriculum/high-school",
          },
        ],
      },
      {
        key: "2-2",
        label: "UK Curriculum",
        path: "/academic-tutoring/uk-curriculum",
        children: [
          {
            key: "2-2-1",
            label: "KEY STAGE 1 | 5-7 YEARS OLD",
            path: "/academic-tutoring/uk-curriculum/key-stage-1",
          },
          {
            key: "2-2-2",
            label: "KEY STAGE 2 | 7-11 YEARS OLD",
            path: "/academic-tutoring/uk-curriculum/key-stage-2",
          },
          {
            key: "2-2-3",
            label: "KEY STAGE 3 | 11-14 YEARS OLD",
            path: "/academic-tutoring/uk-curriculum/key-stage-3",
          },
          {
            key: "2-2-4",
            label: "KEY STAGE 4 | 14-16 YEARS OLD",
            path: "/academic-tutoring/uk-curriculum/key-stage-4",
          },
          {
            key: "2-2-5",
            label: "KEY STAGE 5 | 16-18 YEARS OLD",
            path: "/academic-tutoring/uk-curriculum/key-stage-5",
          },
        ],
      },
      {
        key: "2-3",
        label: "AU Curriculum",
        path: "/academic-tutoring/au-curriculum",
        children: [
          {
            key: "2-3-1",
            label: "Primary 2-6",
            path: "/academic-tutoring/au-curriculum/primary",
          },
          {
            key: "2-3-2",
            label: "Secondary 7-10",
            path: "/academic-tutoring/au-curriculum/secondary",
          },
          {
            key: "2-3-3",
            label: "Senior 11-12",
            path: "/academic-tutoring/au-curriculum/senior",
          },
          {
            key: "2-3-4",
            label: "NAPLAN",
            path: "/academic-tutoring/au-curriculum/naplan",
          },
        ],
      },
      {
        key: "2-4",
        label: "IB Curriculum",
        path: "/academic-tutoring/ib-curriculum",
      },
      {
        key: "2-5",
        label: "IGCSE Curriculum",
        path: "/academic-tutoring/igcse-curriculum",
      },
      {
        key: "2-6",
        label: "AS/A Level Curriculum",
        path: "/academic-tutoring/as-a-level-curriculum",
      },
      {
        key: "2-7",
        label: "College Courses",
        children: [
          {
            key: "2-7-1",
            label: "College Math",
            path: "/college-courses/college-math",
          },
          {
            key: "2-7-2",
            label: "Differential Equations",
            path: "/college-courses/differential-equations",
          },
          {
            key: "2-7-3",
            label: "Discrete Math",
            path: "/college-courses/discrete-math",
          },
          {
            key: "2-7-4",
            label: "College Statistics",
            path: "/college-courses/college-statistics",
          },
          {
            key: "2-7-5",
            label: "Multivariable Calculus",
            path: "/college-courses/multivariable-calculus",
          },
          {
            key: "2-7-6",
            label: "Linear Algebra",
            path: "/college-courses/linear-algebra",
          },
          {
            key: "2-7-7",
            label: "College Biology",
            path: "/college-courses/college-biology",
          },
          {
            key: "2-7-8",
            label: "Molecular Biology",
            path: "/college-courses/molecular-biology",
          },
          {
            key: "2-7-9",
            label: "College Genetics",
            path: "/college-courses/college-genetics",
          },
          {
            key: "2-7-10",
            label: "Anatomy and Physiology",
            path: "/college-courses/anatomy-physiology",
          },
          {
            key: "2-7-11",
            label: "College Chemistry",
            path: "/college-courses/college-chemistry",
          },
          {
            key: "2-7-12",
            label: "Organic Chemistry",
            path: "/college-courses/organic-chemistry",
          },
          {
            key: "2-7-13",
            label: "College Physics",
            path: "/college-courses/college-physics",
          },
          {
            key: "2-7-14",
            label: "College English",
            path: "/college-courses/college-english",
          },
        ],
      },
      {
        key: "2-8",
        label: "IT Courses",
        children: [
          {
            key: "2-8-1",
            label: "C# Programming",
            path: "/it-courses/csharp-programming",
          },
          {
            key: "2-8-2",
            label: "C++ Programming",
            path: "/it-courses/cplusplus-programming",
          },
          {
            key: "2-8-3",
            label: "HTML and Web Development",
            path: "/it-courses/html-web-development",
          },
          {
            key: "2-8-4",
            label: "Java",
            path: "/it-courses/java",
          },
          {
            key: "2-8-5",
            label: "Python",
            path: "/it-courses/python",
          },
        ],
      },
    ],
  },
  {
    key: "3",
    label: "About Us",
    path: "/about-us",
  },
  {
    key: "4",
    label: "Student Corner",
    path: "/student-corner",
  },
  {
    key: "5",
    label: "Book Trial",
    path: "/book-trial",
  },
  {
    key: "6",
    label: (
      <div className="flex items-center gap-2 ">
        Login <IoIosArrowDown className="sm:hidden" />
      </div>
    ),
    children: [
      {
        key: "6-1",
        label: "Live Classes",
        path: "https://mlsclasses.onlineclass.site/login/",
        isExternal: true,
      },
      {
        key: "6-2",
        label: "Test Series",
        path: "https://testprep.mlsclasses.com/",
        isExternal: true,
      },
    ],
  },
];
