import React, { useEffect, useState, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import LayoutTopBottom from "./layout/LayoutTopBottom";
import ScrollToTop from "./layout/ScrollToTop";
import Loader from "./components/loader";

// import PublicRoutes from "./routes/PublicRoutes";
// import AdminRoutes from "./routes/AdminRoutes";

const PublicRoutes = lazy(() => import("./routes/PublicRoutes"));
const AdminRoutes = lazy(() => import("./routes/AdminRoutes"));

const themes = ["light", "dark", "custom1", "custom2"];

function App() {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    const currentIndex = themes.indexOf(theme);
    const nextIndex = (currentIndex + 1) % themes.length;
    setTheme(themes[nextIndex]);
  };

  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <ScrollToTop />
        <Routes>
          <Route
            path="/*"
            element={
              <LayoutTopBottom>
                <PublicRoutes />
              </LayoutTopBottom>
            }
          />
          <Route path="/admin/*" element={<AdminRoutes />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
